export const Page = {
  HOME: "/",
  REGISTER: "/register",
  LOGIN: "/login",
  VERIFY: "/verify",
  FORGET_PASSWORD: "/forget-password",
  NETWORK: "/network",
  USER_PROFILE: "/profile/:user_id",
  USER_PROFILE_EDIT: "/profile/:user_id/sunting",
  CAREER: "/career",
  COURSE: "/course",
  COMPANY: "/company",
  COMPANYDETAIL: "/company/:slug",
  JOB: "/job",
  JOBDETAIL: "/job/:slug",
  AGENDA: "/agenda",
  PAGES: "/pages",
  MITRA: "/mitra",
  LOGINMITRA: "/mitra/login",
  REGISTERMITRA: "/mitra/register",
  CALLFORBOOK: "/callforbook",
  MGTALKS: "/mgtalks",
  ABOUTUS: "/about-us",
  FAQ: "/faq",
  BUSSINESSCOMPETITION: "/bussiness-competition",
  TnC: "/term-and-condition",
};

export const LPDPUserOnlyPage = [
  Page.NETWORK,
  // Page.JOB,
  // Page.JOBDETAIL,
  // Page.COMPANY,
  // Page.COMPANYDETAIL,
  Page.CAREER,
  Page.COURSE,
  Page.USER_PROFILE,
  Page.USER_PROFILE_EDIT,
]

export const RedirectToNetworkOnSignedIn = [
  Page.REGISTER,
  Page.LOGIN,
]