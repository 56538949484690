import React from 'react'
import * as APP_DATA from '../../../core/ConstantData';
import { Icons } from '../../entity/Icons';
import { Page } from '../../entity/Pages';
import SubHeaderView from './SubHeaderView';
import { MitraImage } from '../../entity/MitraImage';

class HeaderView extends React.Component {
  render() {
    const {
      handleShowNavbar,
      navigator,
      is_show,
      is_mobile,
      is_logged_in,
      logout,
      username,
    } = this.props;

    return (
      <div className='sticky top-0 z-10'>
        <div className='md:hidden flex flex-row bg-white pb-4 shadow items-center'>
          <button className="md:hidden mt-4 ml-8 rounded-lg focus:outline-none focus:shadow-outline" onClick={() => handleShowNavbar()}>
            {!is_show
              ? <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                <path fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"></path>
              </svg>
              : <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                <path fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>}
          </button>
          <img alt='' src={APP_DATA.NEW_LOGO_MAGNET} className='cursor-pointer w-32 h-12 mt-4 ml-2' onClick={() => navigator(Page.HOME)}></img>
          <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-16 mt-4' onClick={() => navigator(Page.HOME)}></img>
        </div>
        {(is_show || !is_mobile) && <div id='mgnt-header' className='flex flex-col'>
          <div className='flex justify-center items-center py-4 font-poppins bg-magnet-rose-normal'>
            <div className='flex md:flex-row flex-col justify-between w-10/12'>
              <div id='left-menu' className='flex md:flex-row flex-col md:items-center md:gap-4 gap-1'>
                <img alt='' src={APP_DATA.NEW_LOGO_MAGNET} className='cursor-pointer md:flex hidden w-32' onClick={() => navigator(Page.HOME)}></img>
                <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer md:flex hidden w-16' onClick={() => navigator(Page.HOME)}></img>
                {/* <div className='flex gap-2 cursor-pointer hover:bg-gray-100 duration-500 p-2 rounded-lg fill-black'>
                  <Icons.CLIPBOARD_NOTES />
                  <p className='font-medium'>Interest</p>
                </div> */}
                <div className='flex gap-2 cursor-pointer hover:bg-magnet-rose-light duration-500 p-2 rounded-lg fill-black items-center'
                  onClick={() => window.location.href = Page.NETWORK}>
                  <Icons.GLOBE className='stroke-white' />
                  <p className='font-medium text-white'>Network</p>
                </div>
                {/* <div className='flex gap-2 cursor-pointer hover:bg-gray-100 duration-500 p-2 rounded-lg fill-black'>
                  <Icons.DIARY />
                  <p className='font-medium'>Hobby</p>
                </div> */}
                <div className='flex gap-2 cursor-pointer hover:bg-magnet-rose-light duration-500 p-2 rounded-lg fill-black items-center'
                  onClick={() => navigator(Page.AGENDA)}>
                  <Icons.CALENDAR_ALT />
                  <p className='font-medium text-white'>Agenda</p>
                </div>
                <div className='flex gap-2 cursor-pointer hover:bg-magnet-rose-light duration-500 p-2 rounded-lg fill-black items-center'
                  onClick={() => navigator(Page.BUSSINESSCOMPETITION)}>
                  <img className='w-14' src={APP_DATA.LOGO_BUSINESS_COMPETITION}></img>
                  <p className='font-medium text-white'>Business Competition</p>
                </div>
              </div>

              <div id='right-menu' className='flex md:flex-row flex-col md:items-center md:gap-6 gap-2 md:p-0 p-2'>
                {/* <div className='font-medium'>
                  <select>
                    <option>ID</option>
                    <option disabled>EN</option>
                  </select>
                </div> */}
                {!is_logged_in
                  ? <div className='flex md:flex-row flex-col md:items-center md:gap-6 gap-2'>
                    <div>
                      <button
                        className='px-4 py-2 rounded-xl font-medium border-2 border-white text-white hover:bg-white hover:text-magnet-rose-normal duration-500'
                        onClick={() => navigator(Page.REGISTER)}>
                        Daftar
                      </button>
                    </div>
                    <div>
                      <button
                        className='px-4 py-2 rounded-xl font-medium border-2 border-white hover:bg-magnet-rose-normal bg-white hover:text-white text-magnet-rose-normal duration-500 fill-magnet-rose-normal hover:fill-white'
                        onClick={() => navigator(Page.LOGIN)}>
                        <div className='flex'>
                          <p>Masuk</p>
                        </div>
                      </button>
                    </div>
                  </div>
                  : <div className='flex items-center flex-nowrap'>
                    <button
                      className='px-2 pb-2 pt-1 rounded-xl text-white hover:bg-magnet-rose-light duration-500'
                      onClick={() => window.location.href = '/profile/' + username}>
                      <div className='flex items-center border-b border-white pr-2'>
                        <Icons.USER className='fill-white h-4' />
                        <p>{username}</p>
                      </div>
                    </button>
                    <div className='h-8 w-2 border-l border-white'></div>
                    <button className='bg-transparent hover:bg-magnet-rose-light fill-white rounded-xl duration-500 p-2'
                      onClick={() => logout()}>
                      <Icons.LOGOUT className='h-4 w-4' />
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
          {window.location.pathname !== Page.NETWORK &&
            <SubHeaderView {...this.props} />
          }
        </div>}
      </div>
    )
  }
}

export default HeaderView