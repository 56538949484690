import React from "react";
import { Icons } from "../../../entity/Icons";
import { TopFunction } from '../../../utils/TopFunction';
import { Page } from "../../../entity/Pages";

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator, companies } = this.props;
    const {
      slug,
      name,
      address,
      city_name,
      state_name,
      logo_url,
      jobvacancy_amounts,
    } = companies;

    const partner_path = Page.COMPANYDETAIL.replace(':slug', slug);
    return (
      <div className="border border-magnet-primary shadow flex flex-col p-4 md:p-6 rounded-xl text-neutral-main min-h-full cursor-pointer"
        onClick={() => {TopFunction(); navigator(partner_path)}}>
        <div className="flex flex-1 flex-col items-center justify-center mb-3 md:mb-5">
          <img src={logo_url} alt="Logo perusahaan" className="h-24 min-h-24 max-h-24 min-w-24 max-w-24 rounded-xl mb-3 md:mb-4" />
          <p className="text-base text-center font-bold mb-1">{name}</p>
        </div>
        <div className="flex flex-row items-center mb-1">
          <Icons.MAP_MARKER className="h-4 w-4" />
          <p className="text-sm font-bold ml-2">
            {address ?? ''}
            {city_name ? ', ' + city_name : ''}
            {state_name ? ', ' + state_name : ''}
          </p>
        </div>

        <div className="flex flex-row items-center mb-1">
          <Icons.SUITCASE_ALT className="h-4 w-4" />
          <p className="text-sm text-primary-main ml-2">{jobvacancy_amounts} Lowongan</p>
        </div>
      </div>
    );
  }
}

export default Company;
